import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { useState } from "react";
import Footer from "../../components/footer";

export default function MainLayout() {
  const [open, setOpen] = useState(false);

  const changeState = () => {
    setOpen(!open);
  };

  return (
    <>
      <Navbar action={changeState} />
      <Sidebar state={open} action={changeState} />
      <Outlet />
      <Footer />
    </>
  );
}
