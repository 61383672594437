import React from 'react'
import { Helmet } from "react-helmet";

export default function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Syarat Dan Ketentuan | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7"} />
        <link rel="canonical" href={"https://tabsgame.store/user-privacy"} />
      </Helmet>
      <div className='px-5 md:px-52 lg:px-72 xl:px-80 my-10'>
        <span>
          <span className="text-[18px] md:text-[22px]">Lampiran </span>
          <span className="text-[28px] md:text-[38px] block font-extrabold">Syarat Dan Ketentuan</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Selamat datang di Tabsgame Store! Berikut adalah Syarat dan Ketentuan yang mengatur penggunaan layanan topup game kami. Dengan menggunakan layanan kami, Anda setuju untuk mematuhi dan terikat oleh syarat dan ketentuan berikut. Jika Anda tidak setuju dengan syarat dan ketentuan ini, harap jangan menggunakan layanan kami.
          </span>
        </span>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>1. Definisi</span>
          <ul className='pl-10 text-[14px] md:text-[16px]'>
            <li className='list-disc'><span className='font-bold'>Layanan</span> Layanan topup game yang disediakan oleh tabsgame.store</li>
            <li className='list-disc'><span className='font-bold'>Pengguna</span>: Setiap individu yang menggunakan layanan kami.</li>
            <li className='list-disc'><span className='font-bold'>Konten</span>: Semua informasi, data, teks, perangkat lunak, musik, suara, foto, grafik, video, pesan, atau materi lainnya yang diunggah, diposting, atau ditampilkan oleh pengguna.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>2. Penggunaan Layanan</span>
          <ul className='pl-10 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Pengguna harus berusia minimal 18 tahun atau mendapatkan izin dari orang tua atau wali jika berusia di bawah 18 tahun.</li>
            <li className='list-disc'>Pengguna setuju untuk memberikan informasi yang akurat, terkini, dan lengkap saat menggunakan layanan kami.</li>
            <li className='list-disc'>Pengguna bertanggung jawab penuh atas akun mereka dan semua aktivitas yang terjadi dalam akun mereka.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>3. Pembayaran dan Transaksi</span>
          <ul className='pl-10 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Semua transaksi topup game harus dilakukan melalui metode pembayaran yang disediakan oleh kami.</li>
            <li className='list-disc'>Pengguna setuju untuk membayar semua biaya yang terkait dengan transaksi yang mereka lakukan.</li>
            <li className='list-disc'>Kami berhak menolak atau membatalkan transaksi atas kebijakan kami sendiri.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>4. Kebijakan Pengembalian Dana</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Semua transaksi yang berhasil tidak dapat dibatalkan dan dana tidak dapat dikembalikan kecuali dalam kondisi tertentu di mana kami menentukan bahwa pengembalian dana diperlukan.</li>
            <li className='list-disc'>Untuk permintaan pengembalian dana, pengguna harus menghubungi layanan pelanggan kami dengan memberikan bukti transaksi dan alasan permintaan.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>5. Pembaruan Layanan</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Kami dapat memperbarui atau mengubah layanan kami kapan saja tanpa pemberitahuan terlebih dahulu.</li>
            <li className='list-disc'>Kami tidak bertanggung jawab atas kerugian atau kerusakan yang diakibatkan oleh perubahan atau penghentian layanan.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>6. Batasan Tanggung Jawab</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Layanan kami disediakan "sebagaimana adanya" dan "sebagaimana tersedia" tanpa jaminan apapun.</li>
            <li className='list-disc'>Kami tidak bertanggung jawab atas kerugian langsung, tidak langsung, insidental, khusus, atau konsekuensial yang timbul dari penggunaan atau ketidakmampuan untuk menggunakan layanan kami.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>7. Kewajiban Pengguna</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Pengguna setuju untuk tidak menggunakan layanan kami untuk tujuan ilegal atau tidak sah.</li>
            <li className='list-disc'>Pengguna setuju untuk tidak mengganggu atau merusak layanan kami, termasuk tetapi tidak terbatas pada menyebarkan virus atau kode berbahaya lainnya.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>8. Hak Kekayaan Intelektual</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Semua konten dan materi yang terdapat di layanan kami adalah milik kami atau pemegang lisensi kami dan dilindungi oleh hukum hak cipta dan hak kekayaan intelektual lainnya.</li>
            <li className='list-disc'>Pengguna tidak diizinkan untuk menyalin, mendistribusikan, atau menggunakan konten kami tanpa izin tertulis dari kami.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>9. Penyelesaian Sengketa</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Setiap sengketa yang timbul dari atau terkait dengan syarat dan ketentuan ini akan diselesaikan melalui arbitrase yang mengikat sesuai dengan aturan arbitrase yang berlaku.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>10. Perubahan Syarat dan Ketentuan</span>
          <ul className='pl-10 my-2 text-[14px] md:text-[16px]'>
            <li className='list-disc'>Kami berhak untuk mengubah syarat dan ketentuan ini kapan saja. Perubahan akan diberitahukan kepada pengguna melalui email atau pemberitahuan di situs web kami.</li>
            <li className='list-disc'>Pengguna diharapkan untuk meninjau syarat dan ketentuan ini secara berkala untuk memastikan bahwa mereka memahami persyaratan yang berlaku.</li>
          </ul>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>11. Kontak Kami</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Jika Anda memiliki pertanyaan mengenai syarat dan ketentuan ini, silakan hubungi kami
            <span className='block pl-5 mt-20'>Terima kasih telah menggunakan layanan kami.</span>
            <span className='block pl-5 font-bold text-[16px] md:text-[18px] mt-2'>Tabsgame Store</span>
          </span>
        </p>
      </div>
    </>
  )
}
