import InvoiceCard from "./invoiceCard";
import { useParams } from "react-router-dom";
import { BackgroundFill } from "../../../components/background";
import InvoiceForm from "./invoiceForm";

export default function Invoice() {
  const { invoiceCode } = useParams();
  return (
    <>
      <BackgroundFill />
      {invoiceCode ? <InvoiceCard invoiceCode={invoiceCode} /> : <InvoiceForm />}
    </>
  )
}
