import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchListBanner = createAsyncThunk(
  "fetch/banner",
  async (search, thunkAPI) => {
    try {
      const response = await axios().get(`/banner`)
      return response?.data
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)
