import { faCircleExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

export default function Alert({ message, onClick }) {
  useEffect(() => {
    setTimeout(() => {
      onClick()
    }, 5000);
  }, [message, onClick])

  return (
    <div className={`a-alert bg-red-600/40 border-[1px] border-red-400 block text-[14px] md:text-[16px]`}>
      <div className="flex items-center gap-4">
        <FontAwesomeIcon icon={faCircleExclamation} className="px-2 inline-block" />
        <span className="inline-block">{message}</span>
      </div>
      <div className="absolute top-0 right-0 mr-3 mt-2 cursor-pointer">
        <FontAwesomeIcon icon={faTimes} onClick={onClick} />
      </div>
    </div>
  )
}
