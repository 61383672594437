import React from "react";
import { PaymentItem } from "../../../components/payment";
import { HeaderTitle } from "../../../components/header";

export default function PaymentField({ itemSelected, listPayment, handleChange, loadingSubmit, handleChangePayment, setPriceCalculated, loading }) {
  return (
    <div className="mb-16 md:mb-18">
      <HeaderTitle title={"Pilih Payment"} />
      <div className="block px-3 py-1 md:py-3">
        <div className="grid md:grid-cols-2 gap-4 gap-y-1 py-3">
          {loading ?
            <>
              <div className="pb-3">
                <div className="flex w-full h-[89px] md:h-[105px] bg-slate-500 animate-pulse rounded-md"></div>
              </div>
              <div className="pb-3">
                <div className="flex w-full h-[89px] md:h-[105px] bg-slate-500 animate-pulse rounded-md"></div>
              </div>
              <div className="pb-3">
                <div className="flex w-full h-[89px] md:h-[105px] bg-slate-500 animate-pulse rounded-md"></div>
              </div>
              <div className="pb-3">
                <div className="flex w-full h-[89px] md:h-[105px] bg-slate-500 animate-pulse rounded-md"></div>
              </div>
              <div className="pb-3">
                <div className="flex w-full h-[89px] md:h-[105px] bg-slate-500 animate-pulse rounded-md"></div>
              </div>
            </> :
            listPayment?.map(value =>
              <PaymentItem itemSelected={itemSelected} key={value?.id} payment={value} handleChange={handleChange} required={true} loadingSubmit={loadingSubmit} handleChangePayment={handleChangePayment} setPriceCalculated={setPriceCalculated} />
            )
          }
        </div>
      </div>
    </div>
  );
}
