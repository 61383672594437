import WebIcon from "../icon";
import SideLink from "./sideLink";
import {
  faHome,
  faReceipt,
  faDollar,
  faChampagneGlasses,
} from "@fortawesome/free-solid-svg-icons";

export default function Sidebar({ state, action }) {
  var list = [
    { icon: faHome, value: "Home", link: "/" },
    { icon: faReceipt, value: "Invoice", link: "/invoice" },
    { icon: faChampagneGlasses, value: "Promo", link: "/promo" },
    { icon: faDollar, value: "PriceList", link: "/pricelist" },
  ];

  return (
    <>
      <div
        onClick={action}
        className={
          !state
            ? "hidden"
            : "backdrop-blur-sm z-30 bg-white/10 w-full h-full fixed top-0 left-0 transition-all ease-in-out duration-300 md:hidden"
        }
      ></div>
      <div
        className={
          !state
            ? "hidden"
            : "nav-animate-in fixed z-40 top-0 py-5 px-4 a-bg-default w-3/4 h-full shadow-lg shadow-black/10 md:hidden"
        }
      >
        <div>
          <WebIcon />
        </div>
        <hr className={"mt-5"} />
        <div className={"py-5 pl-5 text-lg font-semibold"}>
          {list.map((data, index) => (
            <div key={"sidelink" + index} onClick={action}>
              <SideLink icon={data.icon} value={data.value} link={data.link} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
