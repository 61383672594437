import { useEffect, useState } from "react";
import Input from "../../../components/form";
import { HeaderTitle } from "../../../components/header";
import Select from "../../../components/select";

export default function IdField({ fields, form, handleChangeByValue, loadingSubmit, loading }) {
  const [inputValue, setInputValue] = useState('')
  const handleChandeIdField = (e) => {
    const newOptions = [...form.options];
    let index = newOptions.findIndex(val => val.name === e.target.name);

    if (index > -1) {
      newOptions[index] = { name: e.target.name, value: e.target.value };
    } else {
      newOptions.push({ name: e.target.name, value: e.target.value });
    }

    handleChangeByValue('options', newOptions);
  }

  useEffect(() => {
    setInputValue(form.options.findIndex(val => val.name === fields.field)?.value)
  }, [form, fields])

  return (
    <>
      <div className="mb-16 md:mb-18">
        <HeaderTitle title={"Game ID"} />
        <div className="block px-3 py-1 md:py-3">
          <div className={`grid ${'grid-cols-' + fields?.length} gap-4 py-3 flex`}>
            {loading ?
              <>
                <div>
                  <div className="bg-slate-500 animate-pulse rounded-lg h-11" />
                  <div className="bg-slate-500 animate-pulse rounded-lg h-2 my-1 w-[70%] md:w-[35%] float-right" />
                </div>
              </> :
              fields?.map((value, i) =>
                <div key={value.field + i}>
                  <div className="grid grid-cols-1 md:grid-cols-4 items-center">
                    <div>
                      <span className="text-[12px] md:text-[14px] a-color-under">
                        {value.placeholder}
                      </span>
                    </div>
                    <div className="col-span-3">
                      {value?.type?.toLowerCase() === 'select' ?
                        <Select name={value.field} value={inputValue} type={value.type} onChange={handleChandeIdField} disabled={loadingSubmit} >
                          <option value={''}>{'-- Silahkan pilih game server ---'}</option>
                          {value?.options?.map(val =>
                            <option key={val.id} value={val.value}>{val.label}</option>
                          )}
                        </Select> :
                        <Input name={value.field} value={inputValue} type={value.type} onChange={handleChandeIdField} autoComplete={'off'} disabled={loadingSubmit} />
                      }
                    </div>
                  </div>
                  <span className="text-[10px] md:text-[10px] leading-none a-color-under float-right block py-3 a-field-description">
                    {value.description}
                  </span>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}
