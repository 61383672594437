import React from "react";

export default function BackgroundTop({image}) {
  return (
    <>
      <div>
        <div className="absolute -z-10 bg-gradient-to-b from-[#373a92aa] to-transparent h-full w-full md:h-96 top-0"></div>
        <div
          style={{ backgroundImage: `url(${image})` }}
          className="absolute -z-20 w-full h-full top-0 bg-origin-border bg-no-repeat bg-contain md:bg-cover md:h-96"
        ></div>
        <div className="absolute -z-10 bg-gradient-to-t from-[#000122] via-[#373a92]/70 md:via-[#373a92]/50 to-[#373a92]/50 h-full w-full md:h-96 bottom-0 md:top-0"></div>
      </div>
    </>
  );
}
