import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faCopy, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { getTransactionByTrxCode } from "../../../fetch/transaction";
import { idrFormat } from "../../../utils/currencyFormat";
import moment from "moment";
import Alert from "../../../components/alert";
import { ModalActionTransaction } from "../../../components/modal";
import { underscoreToTitle } from "../../../utils/underscoreToTitle";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

import useWebSocket from "react-use-websocket";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function InvoiceCard({ invoiceCode }) {
  const navigate = useNavigate()

  const [message, setMessage] = useState([])
  const [invoice, setInvoice] = useState({})
  const [loading, setLoading] = useState({})
  const [err, setErr] = useState('')

  const [openModal, setOpenModal] = useState(false);
  const changeStateModal = () => {
    setOpenModal(!openModal);
  };

  const [mobileLink, setMobileLink] = useState('')
  const [dekstopLink, setDekstopLink] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    getInvoiceByCode()
  }, [invoiceCode])

  useEffect(() => {
    if (invoice?.actions && invoice?.payment?.type?.method === 'ONE_TIME_PAYMENT') {
      setMobileLink(invoice.actions['mobile_web_checkout_url'])
      setDekstopLink(invoice.actions['desktop_web_checkout_url'] ?? invoice.actions['mobile_web_checkout_url'])
    }
    if (invoice?.actions && invoice?.payment?.type?.method === 'VIRTUAL_ACCOUNT') {
      const newOptions = [...options]
      for (let key in invoice?.actions) {
        newOptions.push(
          <div key={key} className="flex justify-between items-center mb-1 text-[12px] md:text-[14px]">
            <span>{underscoreToTitle(key)} : </span>
            <span className="bg-blue-700 px-8 py-3 rounded-md font-bold">
              {invoice?.actions[key]}
              <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboard(invoice?.actions[key])} className="ml-2 cursor-pointer" />
            </span>
          </div>
        )
      }
      setOptions(newOptions)
    } else if (invoice?.payment_options && !invoice?.action_payment) {
      const newOptions = [...options]
      for (let key in invoice?.payment_options) {
        newOptions.push(
          <div className="flex justify-between items-center mb-1 text-[12px] md:text-[14px]">
            <span>{underscoreToTitle(key)} : </span>
            <span className="bg-blue-700 px-8 py-3 rounded-md font-bold">
              {invoice?.payment_options[key]}
            </span>
          </div>
        )
      }
      setOptions(newOptions)
    }
  }, [invoice])

  const getInvoiceByCode = async () => {
    if (invoiceCode) {
      try {
        const response = await getTransactionByTrxCode({ trxCode: invoiceCode, setLoading })
        setInvoice(response?.data?.data)
      } catch (error) {
        setErr(error?.response?.data?.errors)
      }
      setLoading(false)
    }
  }

  const copyToClipboard = (text) => {
    navigator?.clipboard?.writeText(text)
      .then(() => {
        const newMessage = [...message]
        newMessage.push('Success copied number!')
        setMessage(newMessage)
      })
      .catch((error) => {
        const newMessage = [...message]
        newMessage.push('Failed copied number!')
        setMessage(newMessage)
      });
  };

  const hendleRemoveMessage = (i) => {
    const newMessage = [...message]
    newMessage.splice(i, 1)
    setMessage(newMessage)
  }

  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  const downloadQRCode = (qr) => {
    const link = document.createElement('a');
    link.href = qr;
    link.download = 'qr_code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Web Socket
  const { lastJsonMessage } = useWebSocket(
    process.env.REACT_APP_WEB_SOCKET_BASE_URL.concat('/ws?groupid=', invoiceCode),
    {
      share: false,
      shouldReconnect: () => true
    }
  )

  useEffect(() => {
    if ((lastJsonMessage?.status && invoice?.status !== lastJsonMessage?.status) || (lastJsonMessage?.payment && invoice?.payment !== lastJsonMessage?.payment_status)) {
      getInvoiceByCode()
    }
  }, [lastJsonMessage])

  return (
    <>
      <Helmet>
        <title>Invoice {invoiceCode} | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7"} />
      </Helmet>
      <div className="px-5 md:px-60 py-10">
        {
          loading ?
            <>
              <div><FontAwesomeIcon icon={faSpinner} spin /></div>
            </>
            :
            err ?
              <>
                <div className="bg-slate-800/80 px-6 py-4 md:py-5 md:px-10 rounded-md text-[14px] md:text-[16px]">
                  {err}
                  <span className="text-yellow-500 px-2 cursor-pointer underline" onClick={() => navigate(-1)}>
                    Back
                  </span>
                </div>
              </>
              :
              <>
                {message?.map((msg, i) =>
                  <Alert key={msg + i} message={message} onClick={() => hendleRemoveMessage(i)} />
                )}
                <div>
                  {
                    invoice?.action_payment ?
                      <span className="block">
                        <span className="text-[18px] md:text-[22px]">Lanjut & Lengkapi </span>
                        <span className="text-[28px] md:text-[38px] block font-extrabold">Pambayaran Kamu!</span>
                      </span> :
                      <span className="block">
                        <span className="text-[18px] md:text-[22px]">Yeay! Transaksi berhasil dibuat </span>
                        <span className="text-[28px] md:text-[38px] block font-extrabold">Lakukan Pambayaran Kamu!</span>
                      </span>
                  }
                  <span className="">
                    Invoice kamu
                    <span className="font-bold"> {invoiceCode}</span>
                  </span>
                </div>
                {
                  invoice?.status === "Menunggu Pembayaran" ?
                    invoice?.action_payment ?
                      <div className="bg-red-600/40 border-[1px] border-red-400 py-3 px-5 rounded-md my-4 text-[14px] md:text-[16px]">
                        {invoice?.payment?.instruction}
                      </div> :
                      <div className="bg-red-600/40 border-[1px] border-red-400 py-3 px-5 rounded-md my-4 text-[14px] md:text-[16px]">
                        Kita akan kirim pesananmu segera setelah pembayaran diselesaikan!!
                      </div>
                    : ''
                }
                {
                  (invoice?.payment_status === "NEED_REFUNDED") && !invoice?.payment?.type?.is_can_refund &&
                  <div className="bg-red-600/40 border-[1px] border-red-400 py-3 px-5 rounded-md my-4 text-[14px] md:text-[16px]">
                    Transaksi gagal, Pembayaran yang kamu pilih tidak bisa refund secara otomatis <br />
                    Silahkan hubungi admin kita untuk melakukan proses refund
                    <a href="http://wa.me/62895331120803" className='font-bold text-yellow-500 pl-2' target='_blank' rel="noreferrer">Hubungi Kita</a>
                  </div>
                }
                {
                  (invoice?.payment_status === "NEED_REFUNDED" || invoice?.payment_status === "NEED_VOID" || invoice?.payment_status === "REFUND_PROCESS" || invoice?.payment_status === "VOID_PROCESS")
                  && invoice?.payment?.type?.is_can_refund &&
                  <div className="bg-red-600/40 border-[1px] border-red-400 py-3 px-5 rounded-md my-4 text-[14px] md:text-[16px]">
                    Transaksi gagal, Pembayaran kamu akan segera dibatalkan dan direfund otomatis<br />
                    Jika ada yg perlu ditanyakan
                    <a href="http://wa.me/62895331120803" className='font-bold text-yellow-500 pl-2' target='_blank' rel="noreferrer">Hubungi Kita</a>
                  </div>
                }
                {
                  (invoice?.payment_status === "REFUND_FAILED" || invoice?.payment_status === "VOID_FAILED")
                  && invoice?.payment?.type?.is_can_refund &&
                  <div className="bg-red-600/40 border-[1px] border-red-400 py-3 px-5 rounded-md my-4 text-[14px] md:text-[16px]">
                    Transaksi gagal, Refund secara otomatis gagal <br />
                    Silahkan chat admin untuk pemgembalian dana
                    <a href="http://wa.me/62895331120803" className='font-bold text-yellow-500 pl-2' target='_blank' rel="noreferrer">Hubungi Kita</a>
                  </div>
                }
                {
                  (invoice?.payment_status === "REFUNDED" || invoice?.payment_status === "VOIDED") &&
                  <div className="bg-red-600/40 border-[1px] border-red-400 py-3 px-5 rounded-md my-4 text-[14px] md:text-[16px]">
                    Pembayaran telah dibatalkan dan direfund ke customer
                  </div>
                }
                <div>
                  <span className={'block pb-1 px-2 mt-2'}>
                    <span className={'block text-[10px] font-extralight'}>{invoice?.game?.developer}</span>
                    <span className={'block text-[16px] font-semibold'}>{invoice?.game?.name}</span>
                  </span>
                  <div className="grid md:grid-cols-2 gap-4 gap-y-6">
                    <div>
                      <div className="rounded-md bg bg-white/10 px-5 py-5 grid md:grid-cols-2 gap-y-3">
                        <span className={'block'}>
                          <span className={'block text-[10px] font-extralight'}>Game ID</span>
                          <span className={'block text-[14px]'}>{invoice?.options?.map(opt => opt.value).join(' ')}</span>
                        </span>
                        <span className={'block'}>
                          <span className={'block text-[10px] font-extralight'}>Item</span>
                          <span className={'block text-[14px]'}>{invoice?.item?.name}</span>
                        </span>
                        <span className={'block'}>
                          <span className={'block text-[10px] font-extralight'}>Payment</span>
                          <span className={'block text-[14px]'}>{invoice?.payment?.name}</span>
                          <img src={invoice?.payment?.image} alt={invoice?.payment?.name} className="h-6 md:h-8 my-2" />
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between px-5 py-1 items-center text-[12px] md:text-[14px]">
                        <span className="block">Dibuat</span>
                        <div className="rounded-md flex w-max px-5 py-1 font-bold">
                          {moment(invoice?.created_at).format('DD-MM-yyyy HH:mm:ss')}
                        </div>
                      </div>
                      <div className="flex justify-between px-5 py-1 items-center text-[12px] md:text-[14px]">
                        <span className="block">Expired</span>
                        <div className="rounded-md flex w-max px-5 py-1 font-bold">
                          {moment(invoice?.expired).format('DD-MM-yyyy HH:mm:ss')}
                        </div>
                      </div>
                      <div className="flex justify-between px-5 py-1 items-center text-[12px] md:text-[14px]">
                        <span className="block">Status</span>
                        <div className="bg-yellow-500 text-black rounded-md flex w-max px-5 py-1 font-bold capitalize">
                          {invoice?.status?.toLowerCase()}
                        </div>
                      </div>
                      <div className="flex justify-between px-5 py-1 items-center text-[12px] md:text-[14px]">
                        <span className="block">Payment Status</span>
                        <div className="bg-yellow-500 text-black rounded-md flex w-max px-5 py-1 font-bold capitalize">
                          {invoice?.payment_status}
                        </div>
                      </div>
                    </div>
                    {/* 
              Disini section buat jika pembayaran sudah paid
              semua informasi pembayaran muncul disini 
            */}
                    {
                      invoice?.status === "Menunggu Pembayaran" && !invoice?.action_payment &&
                      <>
                        <div>
                          <div>Informasi Pembayaran</div>
                          <hr className="my-2" />
                          {
                            invoice?.payment?.type?.method === 'DYNAMIC' &&
                            <div className="mb-1 flex justify-between text-[12px] md:text-[14px]">
                              <span>QR Code : </span>
                              <span className="block w-fit p-1 rounded-md font-bold">
                                <img src={invoice?.actions['qr']} alt={'qr'} />
                                <button onClick={() => downloadQRCode(invoice?.actions['qr'])} className="py-3 bg-blue-700 hover:bg-blue-800 transition-all duration-200 rounded text-center h-fit float-right my-2 px-8 text-[12px] md:text-[18px] font-semibold">
                                  Download <FontAwesomeIcon icon={faDownload} className="pl-2" />
                                </button>
                              </span>
                            </div>
                          }
                          {
                            mobileLink && invoice?.payment?.type?.method === 'ONE_TIME_PAYMENT' &&
                            <>
                              <div className={mobileLink !== '' ? 'flex justify-between items-center mb-1 md:hidden' : 'hidden'}>
                                <button onClick={() => openNewTab(mobileLink)} className="py-3 bg-blue-700 hover:bg-blue-800 transition-all duration-200 rounded w-full text-center px-8 text-[12px] md:text-[18px] font-semibold">
                                  Klik disini untuk melajutkan pembayaran m <FontAwesomeIcon icon={faAnglesRight} className="pl-2" />
                                </button>
                              </div>
                              <div className={dekstopLink !== '' ? 'justify-between items-center mb-1 hidden md:flex' : 'hidden'}>
                                <button onClick={() => openNewTab(dekstopLink)} className="py-3 bg-blue-700 hover:bg-blue-800 transition-all duration-200 rounded w-full text-center px-8 text-[12px] md:text-[18px] font-semibold">
                                  Klik disini untuk melajutkan pembayaran d <FontAwesomeIcon icon={faAnglesRight} className="pl-2" />
                                </button>
                              </div>
                            </>
                          }
                          {
                            options
                          }
                        </div>
                        <div></div>
                      </>
                    }
                    <div>
                      <div className="block bg-white/10 px-5 py-5 gap-y-3 rounded-md">
                        <div className="flex justify-between font-extrabold md:text-lg">
                          <span>Total Pembayaran : </span>
                          <span>{idrFormat(invoice?.sell_price)}</span>
                        </div>
                        <hr className="my-2" />
                        <div className="font-light text-[12px]">
                          <div className="flex justify-between">
                            <span>Harga Item : </span>
                            <span>{idrFormat(invoice?.item?.price)}</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Fee Pembayaran : </span>
                            <span>{idrFormat(invoice?.sell_price - invoice?.item?.price)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <div className="flex justify-end">
                      {invoice?.action_payment &&
                        <button onClick={changeStateModal} className="py-3 bg-red-700 hover:bg-red-800 transition-all duration-200 rounded block text-center w-full text-[12px] md:text-[18px] font-semibold">
                          Lanjutkan Pembayaran <FontAwesomeIcon icon={faAnglesRight} className="pl-2" />
                        </button>
                      }
                    </div>
                  </div>
                </div>
                <ModalActionTransaction setInvoice={setInvoice} trxId={invoice?.id} handleSetMessage={hendleRemoveMessage} open={openModal} action={changeStateModal} payment={invoice?.payment} paymentRequirement={invoice?.payment_requirement} />
              </>
        }
      </div >
    </>
  )
}
