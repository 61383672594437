import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { fetchListBanner } from "../../fetch/banner";

const initialState = {
  list: [],
  error: null,
  loading: false,
  firstLoad: false,
  timestamp: moment().valueOf()
}

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    acClearBanner(state) {
      state.list = []
      state.error = null
      state.loading = false
      state.firstLoad = false
      state.timestamp = moment().valueOf()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListBanner.pending, (state, action) => {
      state.loading = true
      state.firstLoad = true
      state.timestamp= moment().valueOf()
    })
    builder.addCase(fetchListBanner.fulfilled, (state, action) => {
      state.loading = false
      state.list = action.payload?.data
      state.firstLoad = true
      state.timestamp= moment().valueOf()
    })
    builder.addCase(fetchListBanner.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
      state.firstLoad = false
      state.timestamp= moment().valueOf()
    })
  }
});

export const { acClearBanner } = bannerSlice.actions