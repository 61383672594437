import { useEffect, useState } from "react";
import { idrFormat } from "../../utils/currencyFormat";

export default function Item({ payment, itemSelected, handleChange, loadingSubmit, handleChangePayment, setPriceCalculated }) {
  const [visible, setVisible] = useState(false)
  const [calculatedPrice, setCalculatePrice] = useState(0)

  useEffect(() => {
    setVisible(itemSelected.price === 0 || (itemSelected.price >= payment.min_amount && itemSelected.price <= payment.max_amount))

    const fee = (payment.fee_type === 'amount') ? payment.fee : (itemSelected.price * payment.fee) / 100
    setCalculatePrice(itemSelected.price === 0 ? 0 : Math.ceil((itemSelected.price + fee) / 100) * 100)
  }, [itemSelected, payment])

  return (
    <div className={`${visible ? 'block' : 'hidden'}`}>
      <div className={`a-item block`}>
        <input type="radio" name="payment" id={payment.name} value={payment.id} onChange={(e) => {
          handleChange(e)
          handleChangePayment(payment)
          setPriceCalculated(calculatedPrice)
        }} disabled={(itemSelected.price === 0 || loadingSubmit)} />
        <label htmlFor={payment.name}>
          <div className="grid grid-cols-2 items-center">
            <img src={payment.image} className="h-[23px] md:h-[28px]" alt="" />
            <div>
              <span className="text-[16px] md:text-[18px] block float-right font-bold">
                {idrFormat(calculatedPrice)}
              </span>
            </div>
          </div>
          <hr className="my-3" />
          <span className="text-left block text-[10px] md:text-[12px]">
            {payment.description}
          </span>
        </label>
      </div>
    </div>
  );
}
