import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignRight } from "@fortawesome/free-solid-svg-icons";
import WebIcon from "../icon";
import { Link } from "react-router-dom";

export default function Navbar({ action }) {
  var list = [
    { value: "Home", link: "/" },
    { value: "Invoice", link: "/invoice" },
    { value: "PriceList", link: "/pricelist" },
  ];

  return (
    <>
      <div
        className={
          "w-full py-5 px-4 flex items-center justify-between md:py-6 md:px-20"
        }
      >
        <div>
          <WebIcon />
        </div>
        <div className={"hidden md:block"}>
          <div className={"flex gap-10"}>
            {list.map((data, index) => (
              <div key={`navbar-${index}`}>
                <Link to={data.link}>
                  <span className={"a-nav-link"} key={"nav-link" + index}>
                    {data.value}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <span className={"md:hidden"}>
          <FontAwesomeIcon
            icon={faAlignRight}
            className={"text-xl"}
            onClick={action}
          />
        </span>
      </div>
    </>
  );
}
