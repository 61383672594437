import { useState } from "react";
import { Link } from "react-router-dom";

export default function Game({
  game,
  slug,
  image,
  developer,
  promo,
  promoDesc,
}) {

  const [loading, setLoading] = useState(true)
  return (
    <>
      <Link to={`/p/${slug}`}>
        <div
          className={
            "relative overflow-hidden rounded-lg shadow-lg shadow-white/10 outline-1 border-[1px] border-white/30 hover:border-white  a-color-under hover:text-white hover:cursor-pointer transition-all duration-200"
          }
        >
          <img src={image} alt={`img-${slug}`} className={`w-full ${loading ? 'flex bg-slate-500 animate-pulse h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px]' : ''}`} onLoad={() => setLoading(false)} />
          {promo && (
            <>
              <div className="absolute top-0 -right-20 w-full bg-yellow-400 promo text-black promo py-5 md:py-3" />
              <span className="absolute top-0 right-0 text-black text-[10px] md:text-sm font-semibold rotate-[-340deg] md:rotate-3 md:pr-3 translate-y-1">
                {promoDesc}
              </span>
            </>
          )}
          <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-[#000] to-[#fff0] pt-4 pb-4">
            <span className="block text-center font-semibold leading-none mb-2 md:text-[14px]">
              {game}
            </span>
            <span className="block text-[8px] md:text-[10px] text-center a-color-under">
              {developer}
            </span>
          </div>
        </div>
      </Link>
    </>
  );
}
