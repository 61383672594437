import Game from "./game";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchListGame } from "../../../fetch/game";
import moment from "moment";
import { acClearGame } from "../../../store/slice/gameSlice";

export default function GameList() {
  const dispatch = useDispatch()
  const game = useSelector((state) => state?.game)
  const { list, error, loading, firstLoad, timestamp } = game

  useEffect(() => {
    if (!firstLoad || moment(moment().valueOf()).diff(timestamp, 'minutes') > 1) {
      acClearGame()
      dispatch(fetchListGame())
    }

  }, [list, error, loading, firstLoad, dispatch, timestamp])

  return (
    <>
      <div className={" py-5 px-4 md:py-6 md:px-28 xl:px-72"}>
        <h4 className={"font-bold text-lg md:text-3xl a-color-under py-3 md:py-5 md:mt-5"}>
          Game List
        </h4>
        <div className={"grid grid-cols-3 gap-3 md:gap-4 md:grid-cols-5"}>
          {
            loading ?
              <>
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
                <div className="flex animate-pulse bg-slate-500 h-[148px] md:h-[150px] lg:h-[200px] xl:md:h-[210px] 2xl:md:h-[390px] w-full rounded-lg" />
              </> :
              list?.map((value, index) => (
                <div key={`product-${index}`}>
                  <Game
                    image={value.image}
                    game={value.name}
                    slug={value.slug}
                    developer={value.developer}
                    promo={value.promo}
                    promoDesc={value.promoDesc}
                  />
                </div>
              ))
          }
        </div>
      </div>
    </>
  );
}
