import axios from '../../axios'

export const fetchListPayment = async ({setPayment, setLoading, setErr}) => {
  setLoading(true)
  await axios().get(`/payment`)
  .then((response) => {
    setLoading(false)
    setPayment(response?.data?.data)
  })
  .catch((e) => {
    setLoading(false)
    setErr(e?.response?.data?.message?.errors)
  })
}