import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { gameSlice } from "./slice/gameSlice";
import { bannerSlice } from "./slice/bannerSlice";

const persistConfig = {
  key: 'root',
  storage,
}

const gameSlicePersist = persistReducer(persistConfig, gameSlice.reducer)
const bannerSlicePersist = persistReducer(persistConfig, bannerSlice.reducer)
const store = configureStore({
  reducer: {
    game: gameSlicePersist,
    banner: bannerSlicePersist,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true
})

const persistor = persistStore(store)

export { store, persistor }