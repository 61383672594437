import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchListGame = createAsyncThunk(
  "fetch/game",
  async (search, thunkAPI) => {
    try {
      const response = await axios().get(`/game${(search ? '?search=' + search : '')}`)
      return response?.data
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message)
    }
  }
)

export const fetchDetailGame = async ({slug, setGame, setLoading, setErr}) => {
  setLoading(true)
  await axios().get(`/game/${slug}`)
  .then((response) => {
    setLoading(false)
    setGame(response?.data?.data)
  })
  .catch((e) => {
    setLoading(false)
    setErr(e?.response?.data?.message?.errors)
  })
}