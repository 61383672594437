import background from "../../assets/doXBzUFJRxpaUbuaqz.webp"

export function BackgroundFill() {
  return (
    <>
      <div style={{ backgroundImage: `url(${background})` }} className="absolute -z-20 h-[450px] w-full md:h-[550px] top-0 bg-no-repeat bg-cover " />
      <div className="absolute -z-10 bg-gradient-to-b from-[#373a92e2] to-[#373a92] h-[450px] w-full md:h-[550px] top-0" />
      <div className="absolute -z-10 bg-gradient-to-t from-[#000122] via-[#000122]/60 md:via-[#000122]/60 to-[#373a92c5]/90 h-[450px] w-full  md:h-[550px] top-0"/>
    </>
  )
}
