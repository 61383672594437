import { faXmark, faFish, faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { placeAndOrder } from "../../fetch/transaction";

export default function ConfirmTransaction({ open, action, payment, item, nickname, game, form, priceCalculated, handleSetMessage }) {
  const navigate = useNavigate()

  const [gameID, setGameID] = useState('')
  const [field, setField] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const addField = () => {
      const newField = []
      game?.fields?.forEach(value => {
        newField.push(value.field)
      })
      setField(newField)
    }

    addField()
  }, [game])

  useEffect(() => {
    let newGameID = ''
    field?.forEach((value, i) => {
      (i === 0 ? newGameID = newGameID.concat(form.options.find(val => val.name === value)?.value) : newGameID = newGameID.concat(' ', form.options.find(val => val.name === value)?.value))
    })
    setGameID(newGameID)
  }, [form, field])

  const handleConfirmTransaction = async () => {
    try {
      let response = await placeAndOrder({ payload: form, setLoading })
      navigate(`/invoice/${response?.data?.data?.transaction_code}`)
    } catch (error) {
      handleSetMessage(error?.response?.data?.message + ' : ' + error?.response?.data?.errors)
      setLoading(false)
    }
  }

  return (
    <>
      <div
        className={
          open
            ? "backdrop-blur-sm z-30 bg-white/10 w-full h-screen fixed top-0 left-0 transition-all ease-in-out duration-300"
            : "hidden"
        }
      ></div>
      <div
        className={
          open
            ? "fixed top-0 left-0 w-full h-screen z-[100] a-disable-scroll transition-all ease-in-out duration-300"
            : "hidden"
        }
      >
        <div className={"flex justify-center items-center h-full"}>
          <div className={"w-full md:w-[550px] px-5 md:px-0 -mt-[25%] md:-mt-[0%]"}>
            <div className={"w-full a-bg-default rounded relative p-5 shadow-xl shadow-white/10"}>
              <span className="block text-center text-[20px] md:text-[24px]">
                <FontAwesomeIcon icon={faCheckCircle} /> Konfirmasi Pesanan
                <span className="block text-[14px] md:text-[16px] pt-1 pb-5">Patikan ID dan item yg dipilih sudah benar</span>
              </span>
              <span className={'block pb-1 px-2'}>
                <span className={'block text-[10px] font-extralight'}>{game.developer}</span>
                <span className={'block text-[16px] font-semibold'}>{game.name}</span>
              </span>
              <div className="rounded-md bg bg-white/10 px-5 py-5 grid md:grid-cols-2 gap-y-3">
                <span className={'block'}>
                  <span className={'block text-[10px] font-extralight'}>Game ID</span>
                  <span className={'block text-[14px]'}>
                    {gameID}
                    {game?.checking_nickname &&
                      ''.concat(' (', nickname?.nickname, ')')
                    }
                  </span>
                </span>
                <span className={'block'}>
                  <span className={'block text-[10px] font-extralight'}>Item</span>
                  <span className={'block text-[14px]'}>{item?.name}</span>
                </span>
                <span className={'block'}>
                  <span className={'block text-[10px] font-extralight'}>Payment</span>
                  <span className={'block text-[14px]'}>{payment?.name}</span>
                  <img src={payment.image} alt={payment.name} className="h-6 md:h-8 my-2" />
                </span>
              </div>
              <div className="flex pt-5 justify-end gap-4">
                <button
                  onClick={action}
                  className="py-3 bg-slate-400/10 hover:bg-slate-400/30 transition-all duration-200 rounded block text-center w-full text-[12px] md:text-[14px]"
                >
                  <FontAwesomeIcon icon={faXmark} className="pr-2" /> Tutup
                </button>
                <button className="py-3 bg-red-700 hover:bg-red-800 transition-all duration-200 rounded block text-center w-full text-[12px] md:text-[14px]"
                  onClick={handleConfirmTransaction}>
                  {loading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="cursor-not-allowed pr-2" display={true} /> :
                    <FontAwesomeIcon icon={faFish} className="pr-2" />
                  }
                  Bayar Sekarang
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
