import { HeaderTitle } from "../../../components/header";
import dm from "../../../assets/dm.png";
import Item from "./item";
import { useState } from "react";
import { ModalActionInstruction } from "../../../components/modal";

export default function ItemField({ game, listItem, specialItem, handleChange, handleChangeItem, loadingSubmit, loading }) {
  const [openModal, setOpenModal] = useState(false);
  const [instruction, setInstruction] = useState('')
  const changeStateModal = () => {
    setOpenModal(!openModal);
  };

  const specialHandleChange = (e, value) => {
    if (value?.use_instruction) {
      changeStateModal()
      setInstruction(value.instruction)
    }
    handleChange(e)
  }
  return (
    <div className="mb-16 md:mb-16">
      <ModalActionInstruction open={openModal} action={changeStateModal} instruction={instruction} />
      <HeaderTitle title={"Pilih Item"} />
      <div className="block px-3 py-1 md:py-3">
        {specialItem?.length > 0 ?
          <>
            <span className="text-[14px] text-white">Special items</span>
            <div className="grid grid-cols-2 gap-4 gap-y-1 md:grid-cols-3 py-3">
              {
                loading ?
                  <>
                    <div className="py-1 px-[1px]">
                      <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                    </div>
                    <div className="py-1 px-[1px]">
                      <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                    </div>
                  </> :
                  specialItem?.map(value =>
                    <Item key={value.id} icon={value.item_image?? dm} item={value} handleChange={(e) => specialHandleChange(e, value)} handleChangeItem={handleChangeItem} loadingSubmit={loadingSubmit} />
                  )
              }
            </div>
          </> :
          ''
        }
        <span className="text-[14px] text-white">Regular items</span>
        <div className="grid grid-cols-2 gap-4 gap-y-1 md:grid-cols-3 py-3">
          {
            loading ?
              <>
                <div className="py-1 px-[1px]">
                  <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                </div>
                <div className="py-1 px-[1px]">
                  <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                </div>
                <div className="py-1 px-[1px]">
                  <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                </div>
                <div className="py-1 px-[1px]">
                  <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                </div>
                <div className="py-1 px-[1px]">
                  <div className="flex animate-pulse h-[89px] md:h-[75px] w-full bg-slate-500 rounded-md" />
                </div>
              </> :
              listItem?.map(value =>
                <Item key={value.id} icon={game?.item_image?? dm} item={value} handleChange={handleChange} handleChangeItem={handleChangeItem} loadingSubmit={loadingSubmit} />
              )
          }
        </div>
      </div>
    </div>
  );
}
