import { useState } from "react";

export default function Heading({ game, loading }) {
  const [loadingImage, setLoadingImage] = useState(true)
  return (
    <>
      <div className="absolute -top-[120px] md:-top-[130px] flex items-center">
        <img
          src={game.image}
          alt={game.image}
          className={`${loadingImage ? 'animate-pulse h-[170px] lg:h-[240px] flex bg-slate-500' : ''} w-[130px] md:w-[170px] rounded-3xl mx-4 md:mx-10 md:ml-14 shadow-white/30 shadow-lg`}
          onLoad={() => setLoadingImage(false)}
        />
        <div>
          <span className={`md:text-lg ${loading ? 'flex h-5 animate-pulse bg-slate-500 rounded-md w-20 my-2' : 'block'}`}>{game.developer}</span>
          <span className={`text-[36px] md:text-[50px] w-[90%] md:w-[60%] font-bold a-font-right leading-8 md:leading-[1] ${loading ? 'flex h-12 animate-pulse bg-slate-500 rounded-lg px-20 md:px-56' : 'block'}`}>
            {game.name}
          </span>
          <span className={`text-[10px] md:text-[12px] mt-3 w-[90%] md:w-[60%] ${loading ? 'flex h-10 animate-pulse bg-slate-500 rounded-md' : 'block'}`}>
            {game.description}
          </span>
        </div>
      </div>
    </>
  );
}
