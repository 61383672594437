import { createSlice } from "@reduxjs/toolkit";
import { fetchListGame } from "../../fetch/game";
import moment from "moment";

const initialState = {
  list: [],
  error: null,
  loading: false,
  firstLoad: false,
  timestamp: moment().valueOf()
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    acClearGame(state) {
      state.list = []
      state.error = null
      state.loading = false
      state.firstLoad = false
      state.timestamp = moment().valueOf()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListGame.pending, (state, action) => {
      state.loading = true
      state.firstLoad = true
      state.timestamp= moment().valueOf()
    })
    builder.addCase(fetchListGame.fulfilled, (state, action) => {
      state.loading = false
      state.list = action.payload?.data
      state.firstLoad = true
      state.timestamp= moment().valueOf()
    })
    builder.addCase(fetchListGame.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
      state.firstLoad = false
      state.timestamp= moment().valueOf()
    })
  }
});

export const { acClearGame } = gameSlice.actions