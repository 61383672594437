import { faXmark, faFish, faSpinner, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Input from "../form";
import { underscoreToTitle } from "../../utils/underscoreToTitle";
import { formatPhoneNumber } from "../../utils/phoneNumberFormat";
import { needActionTransaction } from "../../fetch/transaction";

export default function ActionTransaction({ open, action, trxId, payment, paymentRequirement, handleSetMessage, setInvoice }) {
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [payload, setPayload] = useState([])

  useEffect(() => {
    console.log(payload);
  }, [payload])

  const handleOnChange = (e) => {
    let value = e.target.value
    if (e.target.name.toLowerCase().includes('mobile')) {
      value = formatPhoneNumber(e.target.value.replace(/\D/g, ''))
    }

    const newPayload = [...payload]
    let index = newPayload.findIndex(data => data?.name === e.target.name)
    if (index === -1) {
      newPayload.push({ name: e.target.name, value: value })
    } else {
      newPayload[index] = { name: e.target.name, value: value }
    }

    setPayload(newPayload)
  }

  const handleSubmit = async () => {
    setLoadingSubmit(true)
    try {
      const response = await needActionTransaction({trxId, payload, setLoading})
      setInvoice(response?.data?.data)
      setLoadingSubmit(false)
      action()
      setLoading(false)
    } catch (error) {
      handleSetMessage(error?.response?.data?.message + ' : ' + error?.response?.data?.errors)
      setLoadingSubmit(false)
      setLoading(false)
    }
  }

  return (
    <>
      <div
        className={
          open
            ? "backdrop-blur-sm z-30 bg-white/10 w-full h-screen fixed top-0 left-0 transition-all ease-in-out duration-300"
            : "hidden"
        }
      ></div>
      <div
        className={
          open
            ? "fixed top-0 left-0 w-full h-screen z-[100] a-disable-scroll transition-all ease-in-out duration-300"
            : "hidden"
        }
      >
        <div className={"flex justify-center items-center h-full"}>
          <div className={"w-full md:w-[550px] px-5 md:px-0 -mt-[25%] md:-mt-[0%]"}>
            <div className={"w-full a-bg-default rounded relative p-5 shadow-xl shadow-white/10"}>
              <span className="block text-center text-[20px] md:text-[24px]">
                <FontAwesomeIcon icon={faInfoCircle} /> Lengkapi Informasi
                <span className="block text-[14px] md:text-[16px] pt-1 pb-5">Lengkapi informasi pembayaran yang dipilih</span>
              </span>
              <div>
                {
                  paymentRequirement?.map(val =>
                    <div key={val}>
                      <span>{`(${payment?.name}) ${underscoreToTitle(val)}`}</span>
                      <div className="col-span-4">
                        <Input name={val} value={payload?.find(data => data?.name === val)?.value ?? ''} disabled={loadingSubmit} onChange={handleOnChange} />
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="flex pt-5 justify-end gap-4">
                <button type="button"
                  onClick={action}
                  className="py-3 bg-slate-400/10 hover:bg-slate-400/30 transition-all duration-200 rounded block text-center w-full text-[12px] md:text-[14px]"
                >
                  <FontAwesomeIcon icon={faXmark} className="pr-2" /> Tutup
                </button>
                <button type="submit" onClick={handleSubmit} className="py-3 bg-red-700 hover:bg-red-800 transition-all duration-200 rounded block text-center w-full text-[12px] md:text-[14px]">
                  {loading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="cursor-not-allowed pr-2" display={true} /> :
                    <FontAwesomeIcon icon={faFish} className="pr-2" />
                  }
                  Bayar Sekarang
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
