import Heading from "./heading";
import BackgroundTop from "./backgroundTop";
import IdField from "./idField";
import ItemField from "./itemField";
import PaymentField from "./paymentField";
import OrderField from "./orderField";
import { ModalConfirmTransaction } from "../../../components/modal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchDetailGame } from "../../../fetch/game";
import { fetchListPayment } from "../../../fetch/payment";
import { fetchListItem } from "../../../fetch/item";
import Alert from "../../../components/alert";
import { checkNickname } from "../../../fetch/transaction";
import { Helmet } from "react-helmet";

export default function Product() {
  const { product: slug } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState({ options: [] })
  const [itemSelected, setItemSelected] = useState({ price: 0.00 })
  const [paymentSelected, setPaymentSelected] = useState({})
  const [message, setMessage] = useState([])
  const [fieldRequired, setFieldRequired] = useState(['item', 'payment', 'info'])
  const [priceCalculated, setPriceCalculated] = useState(0.00)

  const [nickname, setNickname] = useState({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [game, setGame] = useState({})
  const [loadingGame, setLoadingGame] = useState(false)

  const [item, setItem] = useState([])
  const [specialItem, setSpecialItem] = useState([])
  const [loadingItem, setLoadingItem] = useState(false)


  const [payment, setPayment] = useState([])
  const [loadingPayment, setLoadingPayment] = useState(false)

  useEffect(() => {
    fetchDetailGame({ slug, setGame, setLoading: setLoadingGame, setErr: handleSetMessage });
    fetchListItem({ slug, setItem, setSpecialItem, setLoading: setLoadingItem, setErr: handleSetMessage })
    fetchListPayment({ setPayment, setLoading: setLoadingPayment, setErr: handleSetMessage })
  }, [slug]);

  useEffect(() => {
    const addFieldRequired = () => {
      let i = 0;
      game?.fields?.forEach(value => {
        const newFieldRequired = fieldRequired
        newFieldRequired.splice(i, 0, value.field)
        i++;
        setFieldRequired(newFieldRequired)
      })
    }

    addFieldRequired()
  }, [game, fieldRequired])

  useEffect(() => {
    console.log(form);
  }, [form])

  const changeStateModal = () => {
    setOpenModal(!openModal);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeByValue = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
  }

  const hendleRemoveMessage = (i) => {
    const newMessage = [...message]
    newMessage.splice(i, 1)
    setMessage(newMessage)
  }

  const handleSetMessage = (value) => {
    const newMessage = [...message]
    newMessage.push(value)
    setMessage(newMessage)
  }

  const handleChangeItem = (value) => {
    setItemSelected(value)
  }

  const handleChangePayment = (value) => {
    setPaymentSelected(value)
  }

  const handleSubmit = async () => {
    setLoadingSubmit(true)
    const checkField = await checkFieldRequired()
    if (!checkField) {
      setLoadingSubmit(false)
      return
    }

    if (game.checking_nickname) {
      const payload = {
        game_id: form.options.find(val => val.name === 'data_no')?.value ?? '',
        zone_id: form.options.find(val => val.name === 'data_zone')?.value ?? ''
      }
      try {
        const checkNicknameResponse = await checkNickname({ gameId: game.id, payload, setLoading: () => '' })
        setNickname(checkNicknameResponse?.data?.data)
      } catch (error) {
        const newMessage = [...message]
        newMessage.push(error?.response?.data?.message + ' : ' + error?.response?.data?.errors)
        setMessage(newMessage)
        setLoadingSubmit(false)
        return
      }
    }

    changeStateModal()
    setLoadingSubmit(false)
  }

  const checkFieldRequired = async () => {
    for (let i = 0; i < fieldRequired.length; i++) {
      if (fieldNeedChecked(i)) {
        const newMessage = [...message]
        if (fieldRequired[i] === 'item' || fieldRequired[i] === 'payment') {
          newMessage.push(`Silahkan pilih terlebih dahulu ${fieldRequired[i]} yg tersedia `)
          setMessage(newMessage)
          return false
        } else {
          newMessage.push(`SIlahkan isikan terlebih dahulu ${fieldRequired[i] === 'info' ? 'info' : fieldRequired[i] === 'data_no' ? 'Game ID' : fieldRequired[i] === 'data_zone' ? 'Game Server' : ''}`)
          setMessage(newMessage)
          return false
        }
      }
    }

    return true
  }

  const fieldNeedChecked = (index) => {
    console.log(index);
    if (fieldRequired[index] === 'data_no' || fieldRequired[index] === 'data_zone') {
      let input = form.options.find(val => val.name === fieldRequired[index]);
      return (!input || input?.value === '')
    } else {
      return (!form[fieldRequired[index]] || form[fieldRequired[index]] === '')
    }
  }

  return (
    <>
      <Helmet>
        <title>{game?.name} | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7 \n" + game?.description} />
        <link rel="canonical" href={"https://tabsgame.store/p/" + slug} />
      </Helmet>
      {message?.map((msg, i) =>
        <Alert key={msg + i} message={message} onClick={() => hendleRemoveMessage(i)} />
      )}
      <BackgroundTop image={game?.image} />
      <div className="md:px-24 relative">
        <Heading game={game} loading={loadingGame} />
        <div className="bg-[#000122] w-full rounded-3xl mt-[180px] md:mt-[180px] mb-11 pb-32 pt-28 md:pt-40 px-5 md:px-16 shadow-2xl shadow-[#6467c9]/40">
          <div className="grid grid-cols-10">
            <div className="hidden md:col-span-2 text-[12px] md:block pr-10">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
              ipsum odio ipsam neque doloremque repellat, optio perspiciatis
              asperiores architecto cumque itaque distinctio dolorem commodi quo
              modi, hic ex dicta? Assumenda?
            </div>
            <form onSubmit={(e) => e.preventDefault()} className="md:col-span-8 col-span-10">
              <IdField fields={game?.fields} form={form} handleChangeByValue={handleChangeByValue} loadingSubmit={loadingSubmit} loading={loadingGame} />
              <ItemField game={game} listItem={item} specialItem={specialItem} handleChange={handleChange} handleChangeItem={handleChangeItem} loadingSubmit={loadingSubmit} loading={loadingItem} />
              <PaymentField itemSelected={itemSelected} listPayment={payment} handleChange={handleChange} loadingSubmit={loadingSubmit} handleChangePayment={handleChangePayment} setPriceCalculated={setPriceCalculated} loading={loadingPayment} />
              <OrderField handleSubmit={handleSubmit} form={form} handleChange={handleChange} loadingSubmit={loadingSubmit} />
            </form>
          </div>
        </div>
      </div>
      <ModalConfirmTransaction open={openModal} action={changeStateModal} payment={paymentSelected} item={itemSelected} nickname={nickname} game={game} form={form} priceCalculated={priceCalculated} handleSetMessage={handleSetMessage} />
    </>
  );
}
