import axios from '../../axios'

export const fetchListItem = async ({ slug, setItem, setSpecialItem, setLoading, setErr}) => {
  setLoading(true)
  await axios().get(`/item/${slug}`)
  .then((response) => {
    setLoading(false)
    setItem(response?.data?.data?.items)
    setSpecialItem(response?.data?.data?.special_items)
  })
  .catch((e) => {
    setLoading(false)
    setErr(e?.response?.data?.message?.errors)
  })
}

export const fetchListItemPrice = async ({ gameId, setItems, setLoading, setErr }) => {
  setLoading(true)
  await axios().get(`/item/price/${gameId}`)
  .then((response) => {
    setLoading(false)
    setItems(response?.data?.data)
  })
  .catch((e) => {
    setLoading(false)
    setErr(e?.response?.data?.message?.errors)
  })
}