import { faXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ActionInstruction({ open, action, instruction }) {
  return (
    <>
      <div
        className={
          open
            ? "backdrop-blur-sm z-30 bg-white/10 w-full h-screen fixed top-0 left-0 transition-all ease-in-out duration-300"
            : "hidden"
        }
      ></div>
      <div
        className={
          open
            ? "fixed top-0 left-0 w-full h-screen z-[100] a-disable-scroll transition-all ease-in-out duration-300"
            : "hidden"
        }
      >
        <div className={"flex justify-center items-center h-full"}>
          <div className={"w-full md:w-[550px] px-5 md:px-0 -mt-[25%] md:-mt-[0%]"}>
            <div className={"w-full a-bg-default relative p-5 shadow-xl shadow-white/10 rounded-md"}>
              <span className="block text-center text-[20px] md:text-[24px]">
                <FontAwesomeIcon icon={faInfoCircle} /> Ketentuan Item
                <span className="block text-[14px] md:text-[16px] pt-1 pb-5 mt-5">{instruction}</span>
              </span>
              <div className="flex pt-5 justify-end gap-4">
                <button type="button"
                  onClick={action}
                  className="py-3 bg-slate-400/10 hover:bg-slate-400/30 transition-all duration-200 rounded block text-center w-full text-[12px] md:text-[14px]"
                >
                  <FontAwesomeIcon icon={faXmark} className="pr-2" /> Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
