import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acClearBanner } from "../../../store/slice/bannerSlice";
import { fetchListBanner } from "../../../fetch/banner";
import moment from "moment";

export default function Caraousel() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
  };

  const dispatch = useDispatch()
  const banner = useSelector((state) => state?.banner)
  const { list, error, loading, firstLoad, timestamp } = banner

  useEffect(() => {
    if (!firstLoad || moment(moment().valueOf()).diff(timestamp, 'minutes') > 1) {
      acClearBanner()
      dispatch(fetchListBanner())
    }

  }, [list, error, loading, firstLoad, dispatch, timestamp])

  return (
    <>
      <div className={"flex justify-center"}>
        <div className={"w-full px-0 md:w-3/6"}>
          <Slider {...settings}>
            {list.map(val =>
              <div>
                <div className={"w-full"}>
                  <img
                    src={val.image}
                    alt={val.image}
                    className={"rounded"}
                  />
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
}
