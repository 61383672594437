import axios from "axios";

export default function axiosInstance() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return instance;
}