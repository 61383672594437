export default function Input({...props}) {
  return (
    <>  
      <input
        {...props}
        className="a-form-input text-[12px] md:text-[14px] py-[10px] md:py-[7px] px-[12px] "
      />
    </>
  );
}
