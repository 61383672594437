import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import MainLayout from "./pages/main/layout";
import Home from "./pages/main/home";
import Product from "./pages/main/product";
import PriceList from "./pages/main/priceList";
import Invoice from "./pages/main/invoice";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import UserPrivacy from "./pages/main/userprivacy";
import TermsAndConditions from "./pages/main/termandpolicy";

export default function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path={"/"} element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path="/p/:product" element={<Product />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/invoice/:invoiceCode" element={<Invoice />} />
                {/* <Route path="/promo" element={<Promo />} /> */}
                <Route path="/pricelist" element={<PriceList />} />
                <Route path="/user-privacy" element={<UserPrivacy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );
}
