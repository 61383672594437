import { HeaderTitle } from "../../../components/header";
import Input from "../../../components/form";
import { faCartShopping, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function OrderField({ handleSubmit, form, handleChange, loadingSubmit }) {
  return (
    <div className="mb-5">
      <HeaderTitle title={"Order Details"} />
      <div className="block px-3 py-1 md:py-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-1 py-3">
          <div className="text-[10px] md:text-[12px]">
            Anda akan diberi tau apakah transaksi yang anda lakukan sudah
            berhasil. Jika transaksi gagal atau bermasalah maka anda akan diberi
            tahu lewat whatsapp yang dimasukkan.
          </div>
          <div>
            <label htmlFor="" className="text-[12px]">
              * Whatsapp active
            </label>
            <Input name={'info'} id={'info'} value={form?.info || ''} onChange={handleChange} disabled={loadingSubmit} />
            <div className="my-3">
              <button onClick={handleSubmit} className="text-[12px] md:text-[18px] py-[10px] px-[15px] bg-red-700 hover:bg-red-800 disabled:bg-red-900 disabled:border-[1px] border-white disabled:cursor-not-allowed transition-all duration-200 rounded block text-center w-full" disabled={loadingSubmit}>
                {loadingSubmit ?
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> Checkout Sekarang
                  </> :
                  <>
                    <FontAwesomeIcon icon={faCartShopping} /> Checkout Sekarang
                  </>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
