import React from 'react'
import { Helmet } from "react-helmet";

export default function UserPrivacy() {
  return (
    <>
      <Helmet>
        <title>Kebijakan Privasi | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7"} />
        <link rel="canonical" href={"https://tabsgame.store/terms-and-conditions"} />
      </Helmet>
      <div className='px-5 md:px-52 lg:px-72 xl:px-80 my-10'>
        <span>
          <span className="text-[18px] md:text-[22px]">Lampiran </span>
          <span className="text-[28px] md:text-[38px] block font-extrabold">Kebijakan Privasi</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Kami di Tabsgame Store berkomitmen untuk melindungi privasi dan data pribadi Anda. Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi Anda saat Anda menggunakan layanan topup game kami.
          </span>
        </span>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>1. Infomasi</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Kami mengumpulkan beberapa jenis informasi untuk menyediakan dan meningkatkan layanan kami kepada Anda, termasuk:
            <ul className='pl-10 my-2'>
              <li className='list-disc'><span className='font-bold'>Game ID</span> dan <span className='font-bold'>Server ID</span>: Digunakan untuk pembuatan dan pengiriman transaksi topup.</li>
              <li className='list-disc'><span className='font-bold'>Nomor WhatsApp</span>: Digunakan untuk mengirimkan notifikasi pembaruan status transaksi.</li>
              <li className='list-disc'><span className='font-bold'>Nomor Pembayaran</span>: Digunakan untuk menerima pembayaran dari pelanggan ke gateway pembayaran.</li>
            </ul>
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>2. Penggunaan Informasi</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Informasi yang kami kumpulkan digunakan untuk tujuan berikut:
            <ul className='pl-10 my-2'>
              <li className='list-disc'><span className='font-bold'>Pembuatan</span> dan <span className='font-bold'>Pengiriman Transaksi</span>: Menggunakan Game ID dan Server ID untuk memproses topup game Anda.</li>
              <li className='list-disc'><span className='font-bold'>Notifikasi Transaksi</span>: Menggunakan nomor WhatsApp untuk mengirimkan notifikasi terkait status transaksi Anda.</li>
              <li className='list-disc'><span className='font-bold'>Pemrosesan Pembayaran</span>: Menggunakan nomor pembayaran untuk memfasilitasi pembayaran melalui gateway pembayaran yang aman.</li>
            </ul>
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>3. Perlindungan Data</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Kami mengambil langkah-langkah yang wajar untuk melindungi informasi Anda dari akses, penggunaan, atau pengungkapan yang tidak sah. Langkah-langkah ini termasuk:
            <ul className='pl-10 my-2'>
              <li className='list-disc'>Penggunaan teknologi enkripsi saat mentransmisikan data sensitif.</li>
              <li className='list-disc'>Pembatasan akses ke informasi pribadi hanya untuk karyawan yang memerlukan akses untuk menjalankan tugas mereka.</li>
              <li className='list-disc'>Implementasi prosedur keamanan yang ketat untuk melindungi data dari ancaman eksternal.</li>
            </ul>
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>4. Pengungkapan Informasi</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Kami tidak akan menjual, menyewakan, atau menukar informasi pribadi Anda dengan pihak ketiga tanpa persetujuan Anda, kecuali dalam keadaan berikut:
            <ul className='pl-10 my-2'>
              <li className='list-disc'>Ketika diperlukan oleh hukum atau untuk mematuhi proses hukum yang berlaku.</li>
              <li className='list-disc'>Untuk melindungi hak, properti, atau keselamatan kami, pengguna kami, atau publik.</li>
            </ul>
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>5. Penyimpanan Data</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Kami akan menyimpan informasi pribadi Anda selama diperlukan untuk memenuhi tujuan pengumpulan informasi tersebut, atau sebagaimana ketentuan diatas.
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>6. Hak Anda</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Anda memiliki hak untuk mengakses, atau menghapus informasi pribadi Anda yang kami simpan. Jika Anda ingin menggunakan hak-hak ini, silakan hubungi kami melalui informasi kontak yang tersedia di situs web kami.
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>7. Perubahan pada Kebijakan Privasi Ini</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Anda memiliki hak untuk mengakses, memperbarui, atau menghapus informasi pribadi Anda yang kami simpan. Jika Anda ingin menggunakan hak-hak ini, silakan hubungi kami melalui informasi kontak yang tersedia di situs web kami.
          </span>
        </p>
        <p className='my-10'>
          <span className='text-[20px] md:text-[24px] font-semibold block'>8. Kontak Kami</span>
          <span className='pl-6 text-[14px] md:text-[16px]'>
            Jika Anda memiliki pertanyaan atau keluhan mengenai Kebijakan Privasi ini, silakan hubungi kami
            <span className='block pl-5 mt-20'>Terima kasih telah menggunakan layanan kami.</span>
            <span className='block pl-5 font-bold text-[16px] md:text-[18px] mt-2'>Tabsgame Store</span>
          </span>
        </p>
      </div>
    </>
  )
}
