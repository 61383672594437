import { useEffect, useState } from "react";
import { BackgroundFill } from "../../../components/background";
import Select from "../../../components/select";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { acClearGame } from "../../../store/slice/gameSlice";
import { fetchListGame } from "../../../fetch/game";
import { fetchListItemPrice } from "../../../fetch/item";
import { idrFormat } from "../../../utils/currencyFormat";
import { Helmet } from "react-helmet";

export default function PriceList() {
  const dispatch = useDispatch()
  const game = useSelector((state) => state?.game)
  const { list, error, loading, firstLoad, timestamp } = game

  useEffect(() => {
    if (!firstLoad || moment(moment().valueOf()).diff(timestamp, 'minutes') > 1) {
      acClearGame()
      dispatch(fetchListGame())
    }

  }, [list, error, loading, firstLoad, dispatch, timestamp])

  const [gameSelected, setGameSelected] = useState('')
  const [items, setItems] = useState([])

  useEffect(() => {
    fetchListItemPrice({ gameId: gameSelected, setItems, setLoading: () => '', setErr: () => '' })
  }, [gameSelected])

  return (
    <>
      <Helmet>
        <title>Price List | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7"} />
        <link rel="canonical" href={"https://tabsgame.store/pricelist"} />
      </Helmet>
      <BackgroundFill />
      <div className="py-5 w-full">
        <div className="px-5 md:px-60 py-10">
          <span>
            <span className="text-[18px] md:text-[22px]">Price </span>
            <span className="text-[28px] md:text-[38px] block font-extrabold">List Harga</span>
            <span className="text-[12px] md:text-[14px] block">Kita menyediakan game topup aman, legal dan terpercaya</span>
            <span className="text-[12px] md:text-[14px] block">
              Informasi untuk reseller, special dan API bisa hubungi kita,
              <a href="http://wa.me/62895331120803" className='font-bold text-yellow-500 pl-2' target='_blank' rel="noreferrer">Hubungi Kita</a> </span>
          </span>
          <div className="grid grid-cols-2 lg:grid-cols-4 flex-col-reverse my-5">
            <Select value={gameSelected} onChange={e => setGameSelected(e.target.value)} >
              <option value={''} disabled>Pilih Game</option>
              {list?.map(val =>
                <option key={val.id} value={val.id}>{val.name}</option>
              )}
            </Select>
          </div>
          <div className="my-5 w-full overflow-auto">
            <table className="table-auto w-full text-white/70">
              <thead>
                <tr className="bg-[#000122]/60 text-[14px] md:text-[16px]">
                  <th className="py-3 text-left px-5">No</th>
                  <th className="py-3 text-left px-5">Item</th>
                  <th className="py-3 text-left px-5">Guest</th>
                  <th className="py-3 text-left px-5">Reseller</th>
                  <th className="py-3 text-left px-5">Special</th>
                </tr>
              </thead>
              <tbody className="text-[12px] md:text-[14px]">
                {items.length > 0 ? items?.map((val, i) =>
                  <tr className="bg-[#000122]/10 border-b-2 border-white/10 hover:bg-[#383fff]/10 cursor-pointer">
                    <td className="py-5 text-left px-5">{i + 1}</td>
                    <td className="py-5 text-left px-5">{val.name}r</td>
                    <td className="py-5 text-left px-5">{idrFormat(val?.item_price_guest)}</td>
                    <td className="py-5 text-left px-5">{idrFormat(val?.item_price_reseller)}</td>
                    <td className="py-5 text-left px-5">{idrFormat(val?.item_price_special)}</td>
                  </tr>) :
                  <tr className="bg-[#000122]/10 border-b-2 border-white/10 hover:bg-[#383fff]/10 cursor-pointer">
                    <td className="py-5 text-center px-5" colSpan={5}>Tidak ada item dipilih</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div >
    </>
  );
}
