import { idrFormat } from "../../../utils/currencyFormat";

export default function Item({ item, icon, handleChange, handleChangeItem, loadingSubmit }) {
  return (
    <>
      <div>
        <div className="a-item block">
          <input type="radio" name="item" id={item.id} value={item.id} onChange={(e) => {
            handleChange(e)
            handleChangeItem(item)
          }} disabled={loadingSubmit} />
          <label htmlFor={item.id} onChange={handleChange}>
            <span className="block text-left text-[12px] md:text-[12px]">
              {item.name}
            </span>
            <span className="block text-left text-[14px] md:text-[15px]">
              {idrFormat(item.price)}
            </span>
            <img
              src={icon}
              className="absolute bottom-[-10px] right-[-5px] w-[40px] md:w-[50px]"
              alt={icon}
            />
          </label>
        </div>
      </div>
    </>
  );
}
