import { Helmet } from "react-helmet";
import { BackgroundFill } from "../../../components/background";
import Caraousel from "./caraousel";
import GameList from "./gameList";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Beranda | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7"} />
        <link rel="canonical" href={"https://tabsgame.store"} />
      </Helmet>
      <BackgroundFill />
      <Caraousel />
      <GameList />
    </>
  );
}
