import ActionInstruction from "./modalActionInstruction";
import ActionTransaction from "./modalActionTransaction";
import ConfirmTransaction from "./modalConfirmTransaction";

const ModalConfirmTransaction = ({ ...props }) => {
  return (
    <>
      <ConfirmTransaction {...props} />
    </>
  );
};

const ModalActionTransaction = ({ ...props }) => {
  return (
    <>
      <ActionTransaction {...props} />
    </>
  );
};

const ModalActionInstruction = ({ ...props }) => {
  return (
    <>
      <ActionInstruction {...props} />
    </>
  );
};

export { ModalConfirmTransaction, ModalActionTransaction, ModalActionInstruction };
