import axios from '../../axios'

export const checkNickname = async ({ gameId, payload, setLoading }) => {
  try {
    setLoading(true)
    const response = await axios().post(`/transaction/check-nickname/${gameId}`, payload)

    return response
  } catch (error) {
    throw error
  }
}

export const placeAndOrder = async ({ payload, setLoading }) => {
  try {
    setLoading(true)
    const response = await axios().post(`/transaction/create`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const getTransactionByTrxCode = async ({ trxCode, setLoading }) => {
  try {
    setLoading(true)
    const response = await axios().get(`/transaction/${trxCode}`)

    return response
  } catch (error) {
    throw error
  }
}

export const needActionTransaction = async ({ trxId, payload, setLoading }) => {
  try {
    setLoading(true)
    const response = await axios().post(`/transaction/need-action/${trxId}`, payload)
    return response
  } catch (error) {
    throw error
  }
}