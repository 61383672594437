import { Link } from "react-router-dom";
import Icon from "../../assets/Icon.svg";
import IconText from "../../assets/IconText.svg";

export default function WebIcon() {
  return (
    <>
      <Link to={"/"}>
        <div className={"flex"}>
          <img src={Icon} alt={"icon"} className={"h-6 w-auto mr-2"} />
          <img src={IconText} alt={"iconText"} className={"h-6 w-auto"} />
        </div>
      </Link>
    </>
  );
}
