import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function SideLink({ icon, value, link }) {
  return (
    <>
      <Link to={link}>
        <div className={"py-4 grid grid-cols-10 items-center"}>
          <FontAwesomeIcon icon={icon} className={"col-span-2"} />
          <span>{value}</span>
        </div>
      </Link>
    </>
  );
}
