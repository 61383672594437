import React from 'react'
import WebIcon from '../icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'
import { faLocation } from '@fortawesome/free-solid-svg-icons'

export default function Footer() {
  const year = moment().year().toString()
  return (
    <>
      <footer className='block py-32 bg-[#1a1d68]/30 w-full mt-56'>
        <div className='grid md:grid-cols-3 px-5 md:px-52'>
          <div>
            <WebIcon />
            <p className='pt-3 font-semibold text-[14px] md:text-[18px]'>Website topup aman, legal dan terpercaya</p>
            <p className='text-[12px] md:text-[14px] py-5'>
              Topup otomatis dengan berbagai metode pembayaran dan buka 24/7 <br />
              Informasi terkait program Special Member, Reseller dan API bisa contact admin,
              <a href="http://wa.me/62895331120803" className='font-bold text-yellow-500 pl-2' target='_blank' rel="noreferrer">Hubungi Kita</a>
            </p>
            <div className='flex gap-5 text-[12px] md:text-[14px] font-semibold py-5 pb-16'>
              <a href="/user-privacy" className='cursor-pointer' target='_blank' rel="noreferrer">Kebijakan Privasi</a>
              <a href="/terms-and-conditions" className='cursor-pointer' target='_blank' rel="noreferrer">Syarat & Ketentuan</a>
            </div>
          </div>
          <div>

          </div>
          <div className='text-[16px] md:text-[18px] font-semibold py-5'>
            Our social :
            <div className='text-[12px] md:text-[14px] py-3'>
              <div className='flex gap-3 items-center clear-left py-2'>
                <FontAwesomeIcon icon={faInstagram} className='text-[24px]' /> tabs_gamestore
              </div>
              <div className='flex gap-3 items-center clear-left py-2'>
                <FontAwesomeIcon icon={faWhatsapp} className='text-[24px]' /> +62 895 3311 20803
              </div>
              <div className='flex gap-3 items-center clear-left py-2'>
                <FontAwesomeIcon icon={faLocation} className='text-[24px]' /> Jl. Desa Purorejo - Gg. Damai, Desa Purworejo, Kec. Sragi, Kabupaten Pekalongan - Jawa Tengah | INDONESIA
              </div>
            </div>
          </div>
        </div>
        <div className='px-5 md:px-52 mt-20'>
          <div className='w-full block text-left text-[12px] md:text-[14px]'>
            &copy; <span id="currentYear">{year}</span> Tabsgame Store. All rights reserved.
          </div>
        </div>
      </footer>
    </>
  )
}
