import React, { useState } from 'react'
import Alert from '../../../components/alert'
import Input from '../../../components/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";

const InvoiceForm = () => {
  const navigate = useNavigate()
  const [message, setMessage] = useState([])
  const hendleRemoveMessage = (i) => {
    const newMessage = [...message]
    newMessage.splice(i, 1)
    setMessage(newMessage)
  }

  const [invoiceCode, setInvoiceCode] = useState('')

  const handleChangeInvoice = (e) => {
    setInvoiceCode(e.target.value)
  }

  const handleOnSubmit = () => {
    navigate('/invoice/' + invoiceCode)
  }

  return (
    <>
      <Helmet>
        <title>Invoice | Tabsgame Store</title>
        <meta name="description" content={"Topup game legal, aman dan terpercaya | Website topup game legal, aman dan terpercaya. Pemrosesan otomatis dan buka 24/7"} />
        <link rel="canonical" href={"https://tabsgame.store/invoice"} />
      </Helmet>
      {message?.map((msg, i) =>
        <Alert key={msg + i} message={message} onClick={() => hendleRemoveMessage(i)} />
      )}
      <div className="px-5 md:px-60 py-10">
        <span>
          <span className="text-[18px] md:text-[22px]">Lacak Transaksi </span>
          <span className="text-[28px] md:text-[38px] block font-extrabold">Invoice Kamu!</span>
          <span className="text-[12px] md:text-[14px] block">Kamu bisa menemukkan invoice kamu menggunakan transaksi id ketika kamu membuat transaction</span>
          <span className="text-[12px] md:text-[14px] block">
            Jika belum bisa menemukan transaksi kamu bisa hubungki kita,
            <a href="http://wa.me/62895331120803" className='font-bold text-yellow-500 pl-2' target='_blank' rel="noreferrer">Hubungi Kita</a> </span>
        </span>
        <div className='rounded-md bg py-5 grid md:grid-cols-2 gap-y-3'>
          <form onSubmit={e => e.preventDefault()}>
            <Input name={'invoice-name'} value={invoiceCode} onChange={handleChangeInvoice} placeholder={'Example TBS67290****'} />
            <button onClick={handleOnSubmit} className="my-4 float-right text-[12px] md:text-[16px] py-[10px] px-[30px] bg-red-700 hover:bg-red-800 disabled:bg-red-900 disabled:border-[1px] border-white disabled:cursor-not-allowed transition-all duration-200 rounded block text-center">
              Cari Transaksi <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default InvoiceForm